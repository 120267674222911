import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import DownloadSection from '../containers/DownloadSection';
import CTASection from '../containers/CTA';
//import Input from '../../../common/src/components/Input';
import CheckBox from '../../../common/src/components/Checkbox';
import Button from '../../../common/src/components/Button';
import { Grid } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '20px',
    fontWeight: '500',
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  passwordField: {
    width: '100%',
    paddingRight: theme.spacing(12), // Ensure space for icons
  },
  iconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    zIndex: 1,
  },
  iconButtonTwo: {
    position: 'absolute',
    right: theme.spacing(6),
    zIndex: 1,
  },
  sliderContainer: {
    margin: theme.spacing(2, 0),
    alignItems: 'center',
  },
  slider: {
    paddingTop: theme.spacing(3),
    flex: 1,
  },
  bottomButton: {
    marginLeft: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
}));

const CustomSlider = withStyles({
  root: {
    color: '#2aa275',
    height: 8,
    marginTop: '10px',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const PasswordLengthSelector = ({ passwordLength, setPasswordLength }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    if (passwordLength !== newValue) {
      setPasswordLength(newValue);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value === '' ? '' : Number(event.target.value);
    setPasswordLength(value);
  };

  const handleBlur = () => {
    if (passwordLength < 8) {
      setPasswordLength(8);
    } else if (passwordLength > 1024) {
      setPasswordLength(1024);
    }
  };

  return (
    <div className={classes.sliderContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <InputLabel shrink htmlFor="password-length">
            {t('Password Length')}
          </InputLabel>
          <TextField
            className={classes.textField}
            value={passwordLength}
            onChange={handleInputChange}
            onBlur={handleBlur}
            variant="outlined"
            id="password-length"
            inputProps={{
              step: 1,
              min: 8,
              max: 1024,
              type: 'number',
            }}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <CustomSlider
            className={classes.slider}
            value={passwordLength}
            onChange={handleSliderChange}
            min={8}
            max={128}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </div>
  );
};

const Content = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }

  h2 {
    font-weight: 500;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [passwordLength, setPasswordLength] = useState(16);
  const [includeSymbols, setIncludeSymbols] = useState(false);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeLowercaseCharacters, setIncludeLowercaseCharacters] = useState(
    true
  );
  const [includeUppercaseCharacters, setIncludeUppercaseCharacters] = useState(
    true
  );
  const [excludeSimilarCharacters, setExcludeSimilarCharacters] = useState(
    true
  );
  const [excludeAmbiguousCharacters, setExcludeAmbiguousCharacters] = useState(
    false
  );

  const generatePassword = useCallback(() => {
    let characters = '';
    let i;
    let password = '';

    if (typeof window === 'undefined') {
      return '';
    }

    const random = function () {
      let bs;
      let byte;

      if (window && window.crypto && window.crypto.getRandomValues) {
        // add in-browser implementation
        bs = new Uint32Array(1);
        window.crypto.getRandomValues(bs);
        byte = bs[0];
      } else if (window && window.msCrypto && window.msCrypto.getRandomValues) {
        // add in-browser implementation
        bs = new Uint32Array(1);
        window.msCrypto.getRandomValues(bs);
        byte = bs[0];
      } else {
        throw new Error('No cryptographic random number generator');
      }

      return byte / (0xffffffff + 1);
    };

    if (includeSymbols) {
      characters = characters + '-_#+*!§$%&=?@';
    }
    if (includeNumbers) {
      characters = characters + '0123456789';
    }
    if (includeUppercaseCharacters) {
      characters = characters + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (includeLowercaseCharacters) {
      characters = characters + 'abcdefghijklmnopqrstuvwxyz';
    }
    if (includeSymbols && !excludeAmbiguousCharacters) {
      characters = characters + ',.;:\'~"/(){}[]\\';
    }

    if (excludeSimilarCharacters) {
      const similarCharacters = ['o', '0', 'O', 'i', 'l', '1', 'L'];
      for (i = 0; i < similarCharacters.length; i++) {
        characters = characters.replace(
          new RegExp(similarCharacters[i], 'g'),
          ''
        );
      }
    }

    if (characters.length === 0) {
      return '';
    }
    for (i = 0; i < Math.min(passwordLength, 1024); i++) {
      let pos = Math.floor(random() * characters.length);
      password = password + characters.charAt(pos);
    }

    return password;
  }, [
    passwordLength,
    includeSymbols,
    includeNumbers,
    includeLowercaseCharacters,
    includeUppercaseCharacters,
    excludeSimilarCharacters,
    excludeAmbiguousCharacters,
  ]);

  const defaultPassword = generatePassword();
  const [password, setPassword] = useState(defaultPassword);

  useEffect(() => {
    setPassword(generatePassword());
  }, [generatePassword]);

  function copyToClipboard(fetchContent) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      // Safari
      return navigator.clipboard.write([
        new window.ClipboardItem({
          'text/plain': fetchContent(),
        }),
      ]);
    } else {
      // Firefox & Chrome and everything else
      return fetchContent().then((content) =>
        navigator.clipboard.writeText(content)
      );
    }
  }

  const handleCopy = () => {
    copyToClipboard(() => Promise.resolve(password));
  };

  const handleRegenerate = () => {
    setPassword(generatePassword());
  };

  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        PASSWORD_MANAGER_MORE {
          title
          description
          image {
            publicURL
          }
        }
        CROSS_PLATFORM_CLIENTS {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
      }
      bannerImage: file(
        relativePath: { eq: "image/saasModern/password-generator.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Password Generator')}
          lang={pageContext.lang}
          description={t(
            'Cryptographic secure password generator to create strong passwords! Passwords are generated in your browser and not transmitted over the internet!'
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Password Generator')}
            descriptionText={t(
              'Online password generator for cryptographic secure passwords, generated in your browser'
            )}
            image={Data.bannerImage.childImageSharp.fluid}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <Content>
                <InputLabel shrink htmlFor="password">
                  {t('Password')}
                </InputLabel>
                <TextField
                  className={classes.textField}
                  value={password}
                  id="password"
                  onFocus={(event) => event.target.select()}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: classes.passwordField,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleCopy}
                          className={classes.iconButton}
                          aria-label={t('Copy password')}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                        <IconButton
                          onClick={handleRegenerate}
                          className={classes.iconButtonTwo}
                          aria-label={t('Regenerate password')}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <PasswordLengthSelector
                  passwordLength={passwordLength}
                  setPasswordLength={setPasswordLength}
                />

                <br />
                <CheckBox
                  id="includeSymbols"
                  htmlFor="includeSymbols"
                  labelText={t('Include Symbols ( e.g. -_#+* )')}
                  isChecked={includeSymbols}
                  onChange={setIncludeSymbols}
                />
                <br />
                <CheckBox
                  id="includeNumbers"
                  htmlFor="includeNumbers"
                  labelText={t('Include Numbers ( e.g. 123456 )')}
                  isChecked={includeNumbers}
                  onChange={setIncludeNumbers}
                />
                <br />
                <CheckBox
                  id="includeLowercaseCharacters"
                  htmlFor="includeLowercaseCharacters"
                  labelText={t('Include Lowercase Characters: ( e.g. abcdef )')}
                  isChecked={includeLowercaseCharacters}
                  onChange={setIncludeLowercaseCharacters}
                />
                <br />
                <CheckBox
                  id="includeUppercaseCharacters"
                  htmlFor="includeUppercaseCharacters"
                  labelText={t('Include Uppercase Characters: ( e.g. ABCDEF )')}
                  isChecked={includeUppercaseCharacters}
                  onChange={setIncludeUppercaseCharacters}
                />
                <br />
                <CheckBox
                  id="excludeSimilarCharacters"
                  htmlFor="excludeSimilarCharacters"
                  labelText={t(
                    'Exclude Similar Characters: ( e.g. o,0,O,i,l,1,L )'
                  )}
                  isChecked={excludeSimilarCharacters}
                  onChange={setExcludeSimilarCharacters}
                />
                <br />
                <CheckBox
                  id="excludeAmbiguousCharacters"
                  htmlFor="excludeAmbiguousCharacters"
                  labelText={t(
                    'Exclude Ambiguous Characters: ( e.g. ,.;:\\\'~"/(){}[] )'
                  )}
                  isChecked={excludeAmbiguousCharacters}
                  onChange={setExcludeAmbiguousCharacters}
                />
                <br />
                <br />
                <Button
                  title={t('GENERATE')}
                  onClick={() => {
                    setPassword(generatePassword());
                  }}
                />
                <Button
                  variant="textButton"
                  onClick={handleCopy}
                  className={classes.bottomButton}
                  aria-label={t('Copy password')}
                  title={t('Copy password')}
                />
                <br />
                <br />
                <p>
                  {t(
                    'Your passwords deserve to be secure. You can prevent being hacked by brute force or dictionary attacks or being exploited by social engineering by following some rules:'
                  )}
                </p>
                <ul>
                  <li>
                    <p>
                      <Trans i18nKey="unique_passwords_message">
                        Use unique passwords, so different passwords for
                        different accounts. So if one website is being
                        compromised the attacker does not automatically gain
                        access to other potentially more sensitive websites. Use
                        an enterprise password manager like Psono to store and
                        manage all these passwords.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="random_passwords_message">
                        Use random passwords with at least 16 characters and try
                        to include special characters, numbers, upper and
                        lowercase symbols.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="no_names_in_passwords_message">
                        Do not include the names of family members, pets, or
                        friends in your passwords.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="second_factor_message">
                        Use a second factor whenever possible. Use something
                        like Google Authenticator, Authy, Yubikey and try to
                        avoid SMS if possible.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_personal_info_message">
                        Do not use zip codes, house and phone numbers, birthdays
                        and sequences from your ID or social security number.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="update_software_message">
                        Regularly update your software. Most exploits have been
                        fixed for weeks before attackers are actively exploiting
                        them.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="common_passwords_warning_message">
                        Passwords like QWERTY, 123456, password!, 4nt0n!, ...
                        are inherently insecure and should be avoided. Secure
                        passwords are random and look like qs^?#jD3Ym8rB&D or
                        +Eph6/.q(7t*TjZ
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_patterns_message">
                        Do not construct passwords from a pattern like
                        myPassw0rdForGmail and myPassw0rdForWindows as stolen
                        passwords will automatically allow hackers to guess the
                        password for other accounts.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_biometrics_message">
                        Avoid password mechanisms that cannot be changed yet can
                        be cloned like your iris or fingerprint.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_public_computers_message">
                        Be cautious and avoid logging in on computers of other
                        people.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_untrusted_networks_message">
                        Do not login to sensitive accounts over untrusted WiFi
                        hotspots, free VPN services, and the Tor network, as you
                        never know who is controlling them.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="check_protocols_message">
                        Check for the protocol before you send any sensitive
                        information and avoid unencrypted connections like HTTP
                        or FTP. Better alternatives are HTTPS, SFTP, FTPS.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="auto_lock_devices_message">
                        You should configure your phone and computer to
                        automatically lock when inactive. The duration should
                        not be longer than 30 seconds.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="lock_computer_habit_message">
                        Create a habit of locking the computer when you leave
                        the desk even if it's just for a short period.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="encrypt_connection_message">
                        Protect yourself, especially while traveling, and
                        encrypt your internet connection. VPN software like
                        OpenVPN, IPsec, and Wireguard on your own VPS are the
                        most common solution. Alternatives are SSH tunnels or
                        Socks Proxy configured in your Chrome or Firefox.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="monitor_passwords_message">
                        Check your passwords if they have been breached and set
                        up monitoring for future breaches with the help of{' '}
                        <a
                          href="https://haveibeenpwned.com/"
                          rel="nofollow noopener"
                        >
                          haveibeenpwned.com
                        </a>
                        .
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="store_files_encrypted_message">
                        Store files only encrypted. Full disk encryption with
                        Bitlocker on Windows, FileVault on Mac, or LUKS on Linux
                        are your first line of defense against unauthorized
                        access. GPG and 7-zip are perfect if you want to
                        share/store/send something sensitive.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="backup_passwords_message">
                        You should not rely on a single service so keep a backup
                        of all your passwords in different locations, e.g., a
                        password manager like Psono and a local backup encrypted
                        with 7-zip and stored on Google Drive or Dropbox.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_trust_links_message">
                        Always type the address of important services, like
                        gmail.com or paypal.com, and never trust links that you
                        have been sent. There are different ASCII chars that
                        look the same yet are different making it impossible to
                        distinguish them.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_sharing_passwords_message">
                        Do not share passwords in emails, chat, and so on. You
                        never know when a device of the receiving party is
                        compromised. Instead, use encrypted channels, like
                        Psono's link share, GPG encrypted mails, or secure
                        messengers like Signal.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="never_tell_passwords_message">
                        Never tell anyone your passwords, e.g., employees of
                        bank accounts, your company's administrator, and so on
                        all have their own portal to access your account and
                        will never ask you for your password.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="host_email_securely_message">
                        Use a trusted solution to host your emails. Emails
                        usually allow account resets and as such need to be
                        protected against misuse the same way as your passwords.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="use_own_domain_message">
                        If possible, use your own domain, so if your email
                        provider ever closes your email account, you don't lose
                        access to all your other accounts.
                      </Trans>
                    </p>
                  </li>
                </ul>
                <p>{t('Need help to follow these tips?')}</p>
                <DownloadSection
                  titleText={t(
                    'Use Psono across multiple device types, computers and mobiles'
                  )}
                  descriptionText={t('Cross-platform password manager')}
                  downloadItems={Data.saasModernJson.CROSS_PLATFORM_CLIENTS}
                />
                <CTASection
                  content={t('Try Psono for free?')}
                  buttonLink="https://www.psono.pw/"
                  buttonTitle={t('ACCESS PSONO FREE')}
                />
              </Content>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
